<template>
  <div>
    <JourneyGrid />
  </div>
</template>

<script>
import JourneyGrid from '@/modules/journey/components/JourneyGrid.vue'

export default {
  components: {
    JourneyGrid,
  },
}
</script>
